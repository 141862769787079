<template>
  <span>
    <span v-if="!count" class="grey--text caption">{{ count }}</span>
    <v-btn v-if="count" @click="$emit('showprojects')" color="info" icon small>
      <span class="font-weight-medium">{{ count }}</span>
    </v-btn>
  </span>
</template>

<script>

import db from '@/firebase/init'

export default {
  data() {
    return {
      count: 0
    }
  },

  props: ['id'],

  created() {
    db.collection('projects')
    .where('owner', '==', this.id)
    .get()
    .then(snapshot => {
      if (snapshot.size) {
        this.count = snapshot.size
      }
    })
    .catch(error => {
      console.log(error.message)
    })
  }
}

</script>
